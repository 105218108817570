<template>
    <modal ref="modalCotizarTodos" titulo="Guía generada" tamano="modal-md" adicional="cerrar" @adicional="onClose" no-cancelar no-aceptar>
		<div class="row justify-content-center">
			<div class="col-12">
				<div v-for="(item, index) in guias" :key="index" class="d-middle-center">
					<p class="f-15 fr-light">Se ha generado la guía de envío No. {{item.numero_guia}}</p>
					<div class="wh-36 br-8 d-middle-center f-18 border cr-pointer ml-2" style="background: transparent linear-gradient(180deg, #FFFFFF 0%, #F3F5F7 100%) 0% 0% no-repeat padding-box">
						<i class="icon-map-search-outline" @click="rastrearPedido(item)" />
					</div>
				</div>
			</div>
		</div>
    </modal>
</template>

<script>
export default {
    data(){
        return{
			guias:[]
        }
    },
    computed:{

    },
    methods: {
        toggle(data){
			this.$store.dispatch('pedidos/pedidos_admin/ver_pedido', Number(this.$route.params.id_pedido))

			this.guias = data
            this.$refs.modalCotizarTodos.toggle()
        },
        onClose(){
			this.$refs.modalCotizarTodos.toggle()
            this.$store.commit('pedidos/pedidos_admin/setStateFormOrder', false)
        },
		rastrearPedido(item){
			window.open(item.url, '_blank');
		}
    }
}
</script>
<style lang="scss" scoped>
.text-rojo{
	color: #FF1720
}
.vr{
	display: inline-block; align-self: stretch; width: 1px; min-height: 1em; background-color: currentcolor; opacity: .25;
}
.list-errors{
	.item:last-child{
		border-bottom: 1px solid transparent !important;
	}
}
</style>
