<template>
    <div>
		<modal ref="modalCotizarTodos" titulo="Crear envío" tamano="modal-xl" cerrar no-cancelar no-aceptar>
				<div class="row justify-content-center">
					<div class="col-11">
						<div v-for="(item, index) in cotizacionesExitosas" :key="index">
							<div v-for="(value, idx) in item.cotizacion.data" :key="idx" class="d-flex justify-content-between border br-8 p-2 my-3">
								<div class="d-middle wf-240px">
									<img :src="item.transportadora.logo" alt="" class="wh-48 br-4">
									<p class="f-14 text-general ml-2 fr-semibold">{{ item.transportadora.name }}</p>
									<p class="f-14 ml-2"> {{value.service}}</p>
								</div>
								<div class="my-auto wf-230px">
									<p class="f-14 text-general">Tiempo estimado de entrega</p>
									<p class="f-14 text-general"> {{ value.deliveryDate.date+' '+value.deliveryDate.time }} </p>
								</div>
								<div class="my-auto wf-80px">
									<p class="f-14 text-rojo fr-light">Envío</p>
									<p class="f-14 text-rojo fr-semibold"> $ {{ value.basePrice }}</p>
								</div>
								<div class="my-auto wf-80px fr-light">
									<p class="f-14 text-general">Seguros</p>
									<p class="f-14 text-general"> {{calcularValorSeguros(value.costSummary)}} </p>
								</div>
								<div class="vr" />
								<div class="my-auto wf-80px fr-semibold">
									<p class="f-14 text-general">Total</p>
									<p class="f-14 text-general"> $ {{ value.totalPrice }} </p>
								</div>
								<button class="btn btn-general text-white h-32px w-90px br-8 my-auto" :class="{'cr-progress op-05': loading}" @click="openGuiaGenerada(item, value)" :disabled="loading">
									Generar
								</button>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-12">
								<div class="border br-8 list-errors">
									<div v-for="(data, index) in cotizacionesFallidas" :key="index" class="item d-middle p-2 border-bottom">
										<img :src="data.transportadora.logo" alt="" class="bg-dark wh-48 br-4">
										<div class="f-14 ml-2 text-grey2">
											<p class="text-general fr-semibold">{{ data.transportadora.name }}</p>
											<p>{{ data.cotizacion.error }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</modal>
		<modal-guia-generada ref="refModalGuiaGenerada"/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import ModalGuiaGenerada from './modalGuiaGenerada.vue'
import Paqueteria from '../../../../services/almacen/paqueteria'
export default {
	components: {
		ModalGuiaGenerada,
	},
    data(){
        return {
            motivo: '',
            justificacion: '',
			cotizacionesExitosas:[],
            cotizacionesFallidas:[],
			loading:false

        }
    },
    computed:{
    },
    methods: {
        toggle(data){
			this.cotizacionesExitosas = data.filter(el => el.cotizacion.code == 200)
            this.cotizacionesFallidas = data.filter(el => el.cotizacion.code == 400)
            this.$refs.modalCotizarTodos.toggle()
        },
		calcularValorSeguros(costSummary){
			let totalSeguros = 0;
			costSummary.map(item => {
				item.costAdditionalServices.map(el => {
					if (el.additionalService == "insurance") {
						totalSeguros += el.cost
					}
				})
			})
			return totalSeguros == 0 ? 'No aplica' : totalSeguros
		},
		async openGuiaGenerada(item, value){
			try {
				this.loading = true
				item.informacionGuia.shipment.service = value.service
				item.informacionGuia.id_admin_pedido = this.$route.params.id_pedido
				item.informacionGuia.settings.printFormat = 'PDF'
				item.informacionGuia.settings.printSize = 'STOCK_4X6'
				const payload = item.informacionGuia
				const { data } = await Paqueteria.generarGuia(payload)
				if (data?.error) {
					this.notificacion('Error', data.error, 'error')
					return
				}
				this.$refs.modalCotizarTodos.toggle()
				this.$refs.refModalGuiaGenerada.toggle(data.data)
			} catch (error) {
				this.notificacion('Error', "Error al generar guia", 'error')
			}finally {
            	this.loading = false
            }
		}
    }
}
</script>
<style lang="scss" scoped>
.text-rojo{
	color: #FF1720
}
.vr{
	display: inline-block; align-self: stretch; width: 1px; min-height: 1em; background-color: currentcolor; opacity: .25;
}
.cr-progress{
    cursor: progress;
}
.list-errors{
	.item:last-child{
		border-bottom: 1px solid transparent !important;
	}
}
</style>
